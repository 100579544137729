import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Post from "../Api/Post";
import { login } from "../Data/data";
import Encode from "../EcodeRequest/Encode";
const Auth = ({ children }) => {
  const navigate = useNavigate();
  const { encryptPayload } = Encode();

  const hitLoginApi = async (msisdn) => {
    const data = {
      msisdn: msisdn,
    };
    try {
      const response = await encryptPayload(login, data);
      console.log(response, "response...");
      const status = response?.status;
      console.log(status, "s");
      if (status == 200) {
        return;
      } else {
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.log(error, "error");
      localStorage.clear();
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("ani")) {
      // callonBackend(localStorage.getItem("ani"));
      hitLoginApi(localStorage.getItem("ani"));
    } else {
      navigate("/login");
    }
  }, []);
  return <>{children}</>;
};

export default Auth;
