import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../NewComponentCSS/Layout";
import classes from "../NewPage/Intro.module.css";
import Lottie from "lottie-react";
import loader from "../Animation/loader.json";
import { login } from "../Data/data";
import Post from "../Api/Post";
import { toast } from "react-toastify";
import axios from "axios";
import FailedModal from "../Components/FailedModal";
import Encode from "../EcodeRequest/Encode";
import Modal from "../Components/Modal";

const Header = () => {
  const [msisdn, setMsisdn] = useState("");
  const navigate = useNavigate();
  const [extRef, setExtRef] = useState("");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState("/");

  const closeHandler = () => {
    setOpen(false);
    setMessage("");
    setStatusCode(null);
    setRedirectUrl("/");
    navigate(`/login?extRef=${extRef}`);
  };

  const { encryptPayload } = Encode();

  const checkLoginRequest = async (number, ext_ref) => {
    try {
      const data = {
        msisdn: number,
        extId: ext_ref,
      };
      const response = await encryptPayload(login, data);
      console.log(response, "response...");
      const status = response?.status;
      const redirect_url = response?.response;
      if (status == 200) {
        console.log("enter here 200");
        localStorage.setItem("ani", msisdn);
        localStorage.setItem("serviceId", 1);
        navigate("/home");
      } else if (status == 404) {
        setOpen(true);
        setStatusCode(status);
        setMessage("You are not Subscribed, Please Subscribe!");
        if (redirect_url) {
          setRedirectUrl(redirect_url);
        }
      } else if (status == 402 || status == 403) {
        setOpen(true);
        setStatusCode(status);
        setMessage("Payment Required!");
      } else if (status == 500) {
        setOpen(true);
        setStatusCode(status);
        setMessage("Internal Server Error!");
      } else {
        setOpen(true);
        setStatusCode(status || "Unknown");
        setMessage("Something Went Wrong!");
      }
    } catch (error) {
      console.log(error, "error");
      setOpen(true);
      const status = error?.response?.status;
      if (status == 404) {
        setStatusCode(status);
        setOpen(true);

        setMessage("You are not Subscribed, Please Subscribe!");
      } else if (status == 402 || status == 403) {
        setStatusCode(status);
        setOpen(true);

        setMessage("Payment Required!");
      } else if (status == 500) {
        setStatusCode(status);
        setOpen(true);

        setMessage("Internal Server Error!");
      } else {
        setOpen(true);

        setStatusCode(status || "Unknown");
        setMessage("Something Went Wrong!");
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const msisdnParam = urlParams.get("msisdn");
    setMsisdn(msisdnParam);

    /* FROM HEADER GET THE EXT_REF */
    const ext_ref = urlParams.get("extId");

    setExtRef(ext_ref);

    localStorage.setItem("extId", ext_ref);
    if (msisdnParam) {
      // callonBackend(msisdnParam, ext_ref);
      checkLoginRequest(msisdnParam, ext_ref);
    } else if (ext_ref) {
      navigate(`/login?extId=${ext_ref}`, {
        state: {
          msisdn: msisdn,
        },
      });
    } else {
      navigate(`/login`);
    }
  }, []);
  return (
    <Layout>
      <div className={classes.main}>
        <div className={classes.image}>
          <img
            src="/assets/logo.png"
            alt=""
            style={{ width: "150px", height: "150px", objectFit: "contain" }}
          />
        </div>

        <Lottie animationData={loader} className={classes.animation} />
        <div className={classes.footer_container}>
          <div className={classes.footer_sub_container}>
            <img src="/assets/mtn.png" alt="mtn" className={classes.footer} />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        closeHandler={closeHandler}
        statusCode={statusCode}
        message={message}
        redirectUrl={redirectUrl}
      />
      {/* <FailedModal open={open} closeHandler={closeHandler} text={message} /> */}
    </Layout>
  );
};

export default Header;
