import React, { useEffect } from 'react'

export const Test = () => {

    useEffect(()=>{

    },[])
  return (
    <div>Test</div>
  )
}
