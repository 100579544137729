import React, { useEffect, useState } from "react";
import classes from "./Login.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../NewComponentCSS/Layout";
import SubLayout from "../NewComponentCSS/SubLayout";
import { login } from "../Data/data";
import Post from "../Api/Post";
import Modal from "../Components/Modal";
import InfoModal from "../Components/InfoModal";
import FailedModal from "../Components/FailedModal";
import Encode from "../EcodeRequest/Encode";

const Login = () => {
  const [msisdn, setMsisdn] = useState("");
  const [msisdnFromHeader, setMsisnFromHeader] = useState(false);
  const [extRef, setExtRef] = useState("");

  const { encryptPayload } = Encode();

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ext_ref = urlParams.get("extId");
    setExtRef(ext_ref);
    const msisdn_from_header = location?.state?.msisdn;
    if (msisdn_from_header) {
      setMsisnFromHeader(true);
    }
    setMsisdn(msisdn_from_header);
  }, [location]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "";
    };
  }, []);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("/");

  const hitLoginApi = async (e) => {
    e.preventDefault();
    if (!msisdn) {
      setOpen(true);
      setMessage("Please Enter your number!");
      return;
    }
    if (msisdn.trim().length == 0) {
      setOpen(true);
      return;
    }
    const data = {
      msisdn: msisdn,
      extId: extRef,
    };
    try {
      setLoginLoading(true);
      const response = await encryptPayload(login, data);
      console.log(response, "response...");
      const status = response?.status;
      const redirect_url = response?.response;

      setLoginLoading(false);

      console.log(status, "s");
      if (status == 200) {
        console.log("enter here 200");
        localStorage.setItem("ani", msisdn);
        localStorage.setItem("serviceId", 1);
        navigate("/home");
      } else if (status == 404) {
        setOpen(true);
        setStatusCode(status);
        setMessage("You are not Subscribed, Please Subscribe!");
        if (redirect_url) {
          setRedirectUrl(redirect_url);
        }
      } else if (status == 402 || status == 403) {
        setOpen(true);

        setStatusCode(status);
        setMessage("Payment Required!");
      } else if (status == 500) {
        setOpen(true);

        setStatusCode(status);
        setMessage("Internal Server Error!");
      } else {
        setOpen(true);

        setStatusCode(status || "Unknown");
        setMessage("Something Went Wrong!");
      }
    } catch (error) {
      setLoginLoading(false);

      console.log(error, "error");
      setOpen(true);
      const status = error?.response?.status;
      if (status == 404) {
        setStatusCode(status);
        setOpen(true);

        setMessage("You are not Subscribed, Please Subscribe!");
      } else if (status == 402 || status == 403) {
        setStatusCode(status);
        setOpen(true);

        setMessage("Payment Required!");
      } else if (status == 500) {
        setStatusCode(status);
        setOpen(true);

        setMessage("Internal Server Error!");
      } else {
        setOpen(true);

        setStatusCode(status || "Unknown");
        setMessage("Something Went Wrong!");
      }
    }
  };

  const closeHandler = () => {
    setOpen(false);
    setMessage("");
    setStatusCode(null);
    setRedirectUrl("/");
  };

  return (
    <Layout>
      <SubLayout>
        <div className={classes.main}>
          <div className={classes.logo}>
            <img src="/assets/logo.png" alt="mtn" />
          </div>

          <div className={classes.tabs_container}>
            <div
              className={`${classes.tabs_sub_container} ${classes.grid_one}`}
            >
              <div className={classes.tab_1} onClick={() => navigate("/login")}>
                <p className={classes.tab}>Login</p>
              </div>
              {/* {msisdnFromHeader && (
                <div
                  className={classes.tab_2}
                  // onClick={() => navigate("/subscribe")}
                  onClick={() =>
                    navigate(`/subscribe?extId=${extRef}`, {
                      state: {
                        msisdn: msisdn,
                        msisdnFromHeader: msisdnFromHeader,
                      },
                    })
                  }
                >
                  <p className={classes.tab}>Subscribe</p>
                </div>
              )} */}
            </div>
          </div>

          <div className={classes.form_container}>
            <form className={classes.form} onSubmit={hitLoginApi}>
              <div className={classes.input_group}>
                <span className={classes.country_code}>+260</span>
                <input
                  className={classes.input}
                  type="number"
                  placeholder="ENTER YOUR PHONE NUMBER"
                  value={msisdn}
                  onChange={(e) => setMsisdn(e.target.value)}
                ></input>
              </div>

              <button
                // onClick={() => {
                // hitLoginApi();
                // }}
                type="submit"
                className={classes.subscribe_btn}
                disabled={loginLoading}
              >
                {loginLoading ? (
                  <span className={classes.spinner}></span>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
        <div className={classes.footer_container}>
          <div className={classes.footer_sub_container}>
            <img src="/assets/mtn.png" alt="mtn" className={classes.footer} />
            <p className={classes.footer_text}>
              By clicking <strong>subscribe</strong> , you have read, understood
              and agree to be bound by the <strong>Bigcash </strong> service’s{" "}
              <br />
              <strong> Terms & Conditions and FAQ’s </strong>
            </p>
          </div>
        </div>
      </SubLayout>
      <Modal
        closeHandler={closeHandler}
        message={message}
        open={open}
        redirectUrl={redirectUrl}
        statusCode={statusCode}
      />
      {/* <FailedModal open={open} closeHandler={closeHandler} text={message} /> */}
    </Layout>
  );
};

export default Login;
