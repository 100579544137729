import React from "react";
import classes from "./Modal.module.css";
import { IoIosWarning } from "react-icons/io";
// import { useNavigate } from "react-router-dom";

const Modal = ({ open, closeHandler, message, statusCode, redirectUrl }) => {
  const handleNavigate = () => {
    // closeHandler();
    window.location.href = redirectUrl;
  };
  const closeModal = () => {
    closeHandler();
  };
  return (
    <div
      className={`${
        open
          ? `${classes.modal_container} ${classes.modal_show}`
          : classes.modal_container
      }`}
    >
      <div className={classes.modal_sub_container}>
        <div className={classes.icon}>
          <IoIosWarning className={classes.icon_style} />
        </div>
        <div className={classes.modal_content}>
          {/* <div className={classes.main_heading}> */}
          {/* <p>You are not subscriber</p> */}
          {/* <p>{message}</p>
          </div> */}
          <div className={classes.paragraph}>
            <p>{message}</p>
          </div>
        </div>
        {statusCode == 404 ? (
          <div className={classes.btn_container}>
            <button className={classes.confirm_btn} onClick={handleNavigate}>
              Yes
            </button>
            <button className={classes.cancel_btn} onClick={closeModal}>
              No
            </button>
          </div>
        ) : (
          <div className={classes.btn_container}>
            <button className={classes.cancel_btn} onClick={closeModal}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
