import React, { useState, useEffect, useRef } from "react";
import Layout from "../NewComponentCSS/Layout";
import SubLayout from "../NewComponentCSS/SubLayout";
import classes from "./Subscription.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Post from "../Api/Post";
import { evinaApi, evinaCheckApi, subreq } from "../Data/data";
import InfoModal from "../Components/InfoModal";
import { toast, ToastContainer } from "react-toastify";
import FailedModal from "../Components/FailedModal";
import CryptoJS from "crypto-js";
import axios from "axios";
import Lottie from "lottie-react";
import loader from "../Animation/loader.json";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import Encode from "../EcodeRequest/Encode";

const Subscription = () => {
  const { encryptPayload } = Encode();

  const navigate = useNavigate();
  const location = useLocation();
  const [msisdn, setMsisdn] = useState("");
  const [extRef, setExtRef] = useState("");
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState([
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
  ]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [margin, setMargin] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [subscribeLoading, setSubscribeLoading] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ext_ref = urlParams.get("extId");
    setExtRef(ext_ref);
    const number = location?.state?.msisdn;
    const msisdn_from_header = location?.state?.msisdnFromHeader;
    if (msisdn_from_header) {
      setMsisdn(number);
    }
  }, [location]);

  const closeHandler = () => {
    setOpen(false);
  };

  const getSubData = async (e) => {
    e.preventDefault();
    if (!selectedOption) {
      setOpen(true);
      setMessage("Please Select the Pack!");
      return;
    }
    setSubscribeLoading(true);

    let request = {
      pack: selectedOption,
      extId: extRef ? extRef : null,
    };

    try {
      const response = await encryptPayload(subreq, request);
      console.log("Received Response:", response);
      setSubscribeLoading(false);
      if (response?.status == 200) {
        if (response?.message) {
          window.location.href = response?.message;
        } else {
          setOpen(true);
          setMessage("Something Went Wrong!");
        }
      } else {
        setOpen(true);
        setMessage("Something Went Wrong!");
      }
      // handleResposne(response);
    } catch (error) {
      console.error("Error while encrypting payload:", error);
    }
  };

  return (
    <Layout>
      <SubLayout>
        <ToastContainer />
        <div className={classes.main}>
          <div className={classes.logo}>
            <img src="/assets/logo.png" alt="logo" />
          </div>

          <div className={classes.tabs_container}>
            <div className={classes.tabs_sub_container}>
              <div className={classes.tab_1} onClick={() => navigate("/login")}>
                <p className={classes.tab}>Login</p>
              </div>
              <div
                className={classes.tab_2}
                onClick={() => navigate("/subscribe")}
              >
                <p className={classes.tab}>Subscribe</p>
              </div>
            </div>
          </div>

          {loading ? (
            <Lottie animationData={loader} className={classes.animation} />
          ) : (
            <>
              <div className={classes.form_container}>
                {/* <form className={classes.form} onSubmit={checkEvinaScript}> */}
                <form className={classes.form} onSubmit={getSubData}>
                  {/* <div className={classes.input_group}>
                    <span className={classes.country_code}>+260</span>
                    <input
                      className={classes.input}
                      type="number"
                      placeholder="ENTER YOUR PHONE NUMBER"
                      value={msisdn}
                      required
                      onChange={(e) => setMsisdn(e.target.value)}
                    ></input>
                  </div> */}

                  <div className={classes.radio_btns}>
                    <div className={classes.radio_btn}>
                      <input
                        required
                        className={classes.radio}
                        type="radio"
                        id="daily"
                        name="fav_language"
                        value="Daily"
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      <label className={classes.label}>
                        Daily K2.5
                      </label>
                    </div>
                    <div className={classes.radio_btn}>
                      <input
                        className={classes.radio}
                        type="radio"
                        id="weekly"
                        name="fav_language"
                        value="Weekly"
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      <label className={classes.label}>
                        Weekly K5
                      </label>
                    </div>
                    <div className={classes.radio_btn}>
                      <input
                        className={classes.radio}
                        type="radio"
                        id="monthly"
                        name="fav_language"
                        value="Monthly"
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      <label className={classes.label}>
                        Monthly K15
                      </label>
                    </div>
                  </div>

                  <button
                    type="submit"
                    // onClick={() => {
                    //   // getSubData();
                    //   checkEvinaScript();
                    // }}
                    className={`${
                      margin
                        ? classes.subscribe_btn_down
                        : classes.subscribe_btn
                    }`}
                    disabled={subscribeLoading}
                  >
                    {subscribeLoading ? (
                      <span className={classes.spinner}></span>
                    ) : (
                      "Subscribe"
                    )}
                  </button>
                </form>
              </div>
            </>
          )}
        </div>

        <div className={classes.footer_container}>
          <div className={classes.footer_sub_container}>
            <img src="/assets/mtn.png" alt="mtn" className={classes.footer} />
            <p className={classes.footer_text}>
              By clicking <strong>subscribe</strong> , you have read, understood
              and agree to be bound by the <strong>Bigcash </strong> service’s{" "}
              <br />
              <strong> Terms & Conditions and FAQ’s </strong>
            </p>
          </div>
        </div>

        {open && (
          <FailedModal open={open} text={message} closeHandler={closeHandler} />
        )}
      </SubLayout>
    </Layout>
  );
};

export default Subscription;
